import Home from "home";
import Cmn from "cmn";
import Welcome from "welcome";
import Knockout from "knockout";
import Eligible from "eligible";
import Apply from "apply";

const routes = [
  {
    name: "Home",
    key: "home",
    route: "/home",
    component: <Home />,
  },
  {
    name: "CMN",
    key: "cmn",
    route: "/cmn",
    component: <Cmn />,
  },
  {
    name: "Welcome",
    key: "welcome",
    route: "/welcome",
    component: <Welcome />,
  },
  {
    name: "NotEligible",
    key: "noteligible",
    route: "/noteligible",
    component: <Knockout />,
  },
  {
    name: "Eligible",
    key: "eligible",
    route: "/eligible",
    component: <Eligible />,
  },
  {
    name: "Apply",
    key: "apply",
    route: "/apply",
    component: <Apply />,
  },
];

export default routes;
