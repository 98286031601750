/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { NumericFormat } from 'react-number-format';

function FormField({ inputType, validateForm, ...rest }) {
  //console.log(rest);
  
  const validateMe = () => {
    //console.log('in validateMe');
    validateForm();
  }

  const PrequalCurrency = (props) => (
    <NumericFormat 
      thousandSeparator={true} 
      prefix={'$'}
      customInput={SoftInput}
      valueIsNumericString={true}
      value={props.values[props.name]}
      onValueChange={(values, sourceInfo) => {
        //console.log(values, sourceInfo);
        props.values[props.name]=values.value;
        //validateMe();
      }}
    />
  );

  const PrequalSelect = (props) => (
    <>
    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
      <SoftTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {props.label}
      </SoftTypography>
    </SoftBox>
    <Select input={<SoftInput />} {...props}>
      {props.options.map((option, index) =>
        <MenuItem key={index} value={option}>{option}</MenuItem>
      )}  
    </Select>
    </>
  );

  const PrequalCheckbox = (props) => (  
    <SoftBox display="flex" py={1} mb={0.25}>
      <SoftBox>
        <Switch
          defaultChecked={(props.values[props.name]===true)}
          onChange={(event) => {
            props.values[props.name]=event.target.checked;
            validateMe();
          }}
        />
      </SoftBox>
      <SoftBox width="80%" ml={2}>
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {props.label}
        </SoftTypography>
      </SoftBox>  
    </SoftBox>
  );  

  return (
    <>
      {
        inputType!=='list' && inputType!=='bool' &&
        <SoftTypography
          component="label"
          variant="caption" 
          fontWeight="bold"
          textTransform="capitalize"
        >
          {rest.label}
        </SoftTypography>
      }
      <SoftBox mb={2.5}>
        <Field {...rest} name={rest.name} as={inputType==='list' ? PrequalSelect : inputType==='bool' ? PrequalCheckbox : inputType==='currency' ? PrequalCurrency :  SoftInput} />
        <SoftBox mt={0.75}>
          <SoftTypography component="div" variant="caption" color="error">
            <ErrorMessage name={rest.name} />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}

export default FormField;

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
};
