import React, { useEffect, useState } from 'react';

import axios from "axios"

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import LinearProgress from '@mui/material/LinearProgress';
import Link from "@mui/material/Link";
import typography from "assets/theme/base/typography";
import CircularProgress from '@mui/material/CircularProgress';

function Knockout() {
  const { size } = typography;
  const [knockout, setKnockout] = useState('');
  const [footer, setFooter] = useState('');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const getPrequal = async function () {
      const url = process.env.REACT_APP_PUBLIC_API;
      const hostname = window.location.host.split('.')[0];
      const tenant = await axios.get(url + "tenant/" + hostname);
      setLoading(true);
      const results = await axios.get(url + "prequal/" + tenant.data.tenantId);
      const prequal = results.data;

      const knockout = prequal.filter(e=>{return e.sk==='pages-knockout'})[0].data;
      setKnockout(knockout);

      const footer = prequal.filter(e=>{return e.sk==='pages-footer'})[0].data;
      setFooter(footer);

      setLoading(false);  
    }
    getPrequal();
  }, [])
  
  return (
            <>


    <SoftBox
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
      sx={{position: "fixed", top: "0px", zIndex: "999"}}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
        px={1.5}
      >
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
        <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>
    </SoftBox>

      <Card>

      
        {loading &&
        <>
        <Grid container>    
          <Grid item xs={4}>
          {/**
            <SoftBox component="img" src={act_logo} mt={6} ml={16}>
            </SoftBox>
          **/}
          </Grid>
        </Grid>    
        <SoftBox>
          <SoftTypography mt={8} variant="h3" textAlign="center">
            <CircularProgress color="dark"/>
          </SoftTypography>
        </SoftBox>
        </>  
      }   
      {!loading &&
        <SoftBox sx={{ height: '2px;' }}>   
        </SoftBox>  
      }
        <SoftBox pt={0} pb={8}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={10}>
              <Card>
                <SoftBox>
                  <SoftBox minHeight={600} p={6}>
                    <div dangerouslySetInnerHTML={{ __html: knockout }} />
                  </SoftBox>
                </SoftBox>
              </Card>    
              <SoftBox mt={12} mb={1}>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: footer }} />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>


      </Card>
    <SoftBox
      sx={{position: "fixed", left: "0", bottom: "0", right: "0"}}
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
      >
        Powered by 
        &copy; {new Date().getFullYear()},
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
            Access Capital Technologies
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
      <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>


    </SoftBox>

      </>
  );
}

export default Knockout;
