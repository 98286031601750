import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Switch from "@mui/material/Switch";
import SoftInput from "components/SoftInput";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import cmn_logo from "assets/images/cmn-logo.png";
import cama_logo from "assets/images/cama-logo.png";
import me_logo from "assets/images/me-logo.png";
import act_logo from "assets/images/act-logo.png";
import typography from "assets/theme/base/typography";
import Link from "@mui/material/Link";

function CMN() {
	const { size } = typography;
	const [capital, setCapital] = useState(false);
	const [workforce, setWorkforce] = useState(false);
	const [zip, setZip] = useState('');

	return (
		<>
	      <Card>
			<SoftBox width="80%" textAlign="center" mx="auto" mt={4}>	
	      	<Grid container justifyContent="center">
            	<Grid item xs={3}>
					<SoftBox component="img" src={cmn_logo}
					alt="Colorado Manufacturing Network Logo" width="100%" />
				</Grid>
            	<Grid item xs={9}>
	        		<SoftTypography variant="h2" fontWeight="800" mr={6} mt={1.5}>
	            		Welcome, how can we help you today?
	          		</SoftTypography>
	          	</Grid>
	          	<Grid item xs={12}>
					<SoftTypography variant="body2" mt={3}>
	            		This site has been designed to help manufacturers address the two
	            		biggest needs they face today: Access to capital, and access to 
	            		programs and funding to grow a tailored, trained and skilled 
	            		workforce. CAMA members receive special rates! To get started, just check the box below and we will 
	            		help you begin to address the issue you want to resolve today.
	          		</SoftTypography>	          	
	          	</Grid>
	        </Grid>
	        <SoftBox textAlign="center" mx="auto" mt={5}>
		        <Grid container justifyContent="center">  	
	            	<Grid item xs={6}>
			            <SoftBox mt={2} display="flex" ml={1}>
			             	<SoftBox>
			                	<Switch
			                		//disabled={workforce===true}
			                  		checked={capital===true}
			                  		onChange={() => {
			                  			const isCapital = capital;
			                  			setCapital(!capital);
			                  			setWorkforce(false);
			                  		}}
			                	/>  
			            	</SoftBox>
			            	<SoftBox width="80%" ml={2}>
				                <SoftTypography textAlign="left" variant="h4" fontWeight="bold" color="text">
				                  I need access to capital
				                </SoftTypography>
				            </SoftBox>    
			            </SoftBox>	
	            	</Grid>
	            	<Grid item xs={6}>
			            <SoftBox mt={2} display="flex">
			            	<SoftBox>
			                	<Switch
			                		//disabled={capital===true}
			                  		checked={workforce===true}
			                  		onChange={() => {
			                  			const isWorkforce = workforce;
			                  			setWorkforce(!workforce);
			                  			setCapital(false);
			                  		}}
			                	/>  
			            	</SoftBox>
			            	<SoftBox width="100%" ml={2}>
			                	<SoftTypography textAlign="left" variant="h4" fontWeight="bold" color="text">
			                  		I need workforce development support
			                	</SoftTypography>
			                </SoftBox>	
			            </SoftBox>  
	            	</Grid>
	            </Grid>	
	        </SoftBox>

	        <Grid container>
	        	<Grid item xs={2}>
	        	</Grid>
	        	<Grid item xs={8}>
	        		{capital===false ||
	        			<>
			        			<SoftBox mt={3}>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking for equipment financing
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>Access Capital Technologies (ACT)</strong>, our lender referral
									    		partner, can recommend the best equipment loan for your business based on a few
									    		simple questions. To get started click <a href="/welcome" target="_blank">here</a>
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking for operating capital
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>Access Capital Technologies (ACT)</strong>, our lender referral
									    		partner, can match your business with lenders that provide working capital loans
									    		based on your specific needs. To get started click <a href="/welcome" target="_blank">here</a>
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking to access Grants that support manufacturers
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="h4" fontWeight="800" textAlign="left">
							            		Small Manufacturing Recovery Grants
							          		</SoftTypography>
							          		<SoftTypography variant="body2" textAlign="left">
									    		Are you a small manufacturer in Colorado looking for opportunities to 
									    		recover from recent economic challenges? The Manufacturer's Edge Small 
									    		Manufacturing Recovery Grants program is here to help. Our program offers 
									    		multiple grant opportunities, including some that are tailored to meet 
									    		the specific needs of rural manufacturing communities.
									  		</SoftTypography>
							          		<SoftTypography variant="h5" textAlign="left" mt={1} ml={1}>
									    		Eligible projects may include
									  		</SoftTypography>
							          		<SoftTypography variant="body2" textAlign="left" ml={3}>
									    		- Succession Planning<br/>
									    		- Financial Training & Decision Making<br/>
												- Search Engine Optimization<br/>
												- Lead Generation<br/>
												- Voice of the Customer<br/>
												- And much more<br/>
									  		</SoftTypography>
							          		<SoftTypography variant="body2" textAlign="left" mt={1}>
									    		Grant funding is awarded on a rolling first-come, first-served basis and is 
									    		limited so be sure to apply soon to take advantage of these funding opportunities.
									  		</SoftTypography>
							          		<SoftTypography variant="h5" textAlign="left" mt={1}>
									    		<a href="https://manufacturersedge.com/grants-apply/" target="_blank">Apply here</a> and find out if you are eligible
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
								</SoftBox>
						</>		
					}	
				</Grid>	
			</Grid>	


	        <Grid container>
	        	<Grid item xs={2}>
	        	</Grid>
	        	<Grid item xs={8}>
	        		{workforce===false ||
	        			<>
	        				<SoftBox mt={3}>
								<SoftInput
					              type="number"
					              placeholder="Enter your business zip code ..."
					              value={zip}
					              onChange={(event) => {
			                        setZip(event.target.value)
			                      }}
					            />
					        </SoftBox>    
					        {zip.length < 5 ||
			        			<SoftBox mt={2}>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking to hire new employees
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>The Jefferson County Business & Workforce Center</strong> can help 
									    		you by recruiting candidates for open positions. 
									    		To learn more click <a href="#">here</a>
									  		</SoftTypography>
									  		<SoftTypography variant="body2" textAlign="left" mt={1}>
									    		- <strong>The Colorado Advanced Manufacturing Association</strong> has a program 
									    		that recruits, trains and provides on-the-job-training for new 
									    		candidates to manufacturing. To learn more click <a href="#">here</a>
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking for funding to pay for an internship
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>The Arvada Chamber of Commerce</strong> has WBLIP funds that 
									    		will reimburse you up to $10,000 to help offset the cost on- the- job-training. 
									    		To learn more click <a href="#">here</a>
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking for funding to pay for on-the-job-the-training
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>The Arvada Chamber of Commerce</strong> has WBLIP funds that will reimburse you up to $10,000 to help offset the cost on- the- job-training. To learn more click <a href="#">here</a>
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking for funding to pay job fairs
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>The Arvada Chamber of Commerce</strong> has WBLIP funds that will reimburse you up to $2,500 to help offset the cost of a Jobs Fair at your business. To learn more click <a href="#">here</a>
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="info">
									  			I am looking for funding to develop an Apprenticeship Program for my company
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>The Arvada Chamber of Commerce</strong> has WBLIP funds that will reimburse you up to $6,500 to create an Apprenticeship. To learn more click <a href="#">here</a>
									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
								</SoftBox>
							}	
						</>		
					}	
				</Grid>	
				<Grid item xs={6}>
	        	</Grid>

			</Grid>	

	        <SoftBox textAlign="center" mx="auto" mt={16}>
	        	<hr/>
		        <Grid container justifyContent="center">  	
	            	<Grid item xs={4}>
						<SoftBox component="img" src={me_logo} mt={2}
						alt="Manufacturers Edge Logo" width="80%" />
					</Grid>
	            	<Grid item xs={4}>
						<SoftBox component="img" src={cama_logo} mt={3} ml={3}
						alt="CAMA Logo" width="90%" />
					</Grid>
	            	<Grid item xs={4}>
						<SoftBox component="img" src={act_logo} mt={1}
						alt="ACT Logo" width="65%" />
					</Grid>
		        </Grid>
		    </SoftBox>      	
			</SoftBox>
	      </Card>
	          <SoftBox
      sx={{position: "fixed", left: "0", bottom: "0", right: "0"}}
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
      >
        Powered by 
        &copy; {new Date().getFullYear()},
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
            Access Capital Technologies
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
      <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>


    </SoftBox>

		</>
	)	
}

export default CMN;