import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Switch from "@mui/material/Switch";

const handleSwitch = (event, question) => {
  question.value = event.target.checked;
}

function Eligibility({questions}) {
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={2}>
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="regular">
            First, let's determine eligibility. Check all that apply
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox>
        <Grid container justifyContent="center">
          <Grid item xs={8}>
            <SoftBox textAlign="left">
              {questions.map((question, index) =>
                <SoftBox key={index} display="flex" py={1} mb={0.25}>
                  <SoftBox>
                    <Switch
                      defaultValue={(question.value)}
                      onChange={(event) => {
                        handleSwitch(event, question)
                      }} 
                    />
                  </SoftBox>
                  <SoftBox width="80%" ml={2}>
                    <SoftTypography key={index} variant="button" fontWeight="regular" color="text">
                      {question.question}
                    </SoftTypography>
                  </SoftBox>  
                </SoftBox>  
              )}  
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>


  );
}
  
export default Eligibility;