import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Switch from "@mui/material/Switch";
import SoftInput from "components/SoftInput";
import typography from "assets/theme/base/typography";
import Link from "@mui/material/Link";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import cmn_logo from "assets/images/cmn-logo.png";
import cama_logo from "assets/images/cama-logo.png";
import me_logo from "assets/images/me-logo.png";
import act_logo from "assets/images/act-logo.png";

function Home() {

	const { size } = typography; 
	const [capital, setCapital] = useState(true);
	const [workforce, setWorkforce] = useState(false);
	const [zip, setZip] = useState('');

	return (
            <>




    <SoftBox
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
      sx={{position: "fixed", top: "0px", zIndex: "999"}}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
        px={1.5}
      >
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
        <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>
    </SoftBox>
	  <Card sx={{
	  		opacity: 'rgba(0,0,0,0.1)',
	  		backgroundImage: 'url("https://www.accesscapital.tech/wp-content/uploads/2023/06/home-bg-faded-2.jpg")',
				backgroundPosition: 'bottom center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'}}>
			<SoftBox width="90%" textAlign="center" mx="auto" mt={8.5}>	
	      	<Grid container>
            	<Grid item xs={3}>
            		<Link href="https://www.accesscapital.tech" target="_blank">
									<SoftBox component="img" src={act_logo}
										alt="Colorado Manufacturing Network Logo" width="100%" />
								</Link>
							</Grid>
            	<Grid item xs={12}>
            		<Grid container justifyContent="center">
		        		<Grid item xs={12}>
		        			<SoftTypography color="text" variant="h2" mt={1.5}>
		            			Welcome Colorado Manufacturer
		          			</SoftTypography>
	          			</Grid>
		            	<Grid item xs={12}>
			        		<SoftTypography color="text" variant="h4">
			            		How Can We Help You Today?
			          		</SoftTypography>
			          	</Grid>
			        	</Grid>
			    		</Grid>      	
	          	<Grid item xs={12}>
								<SoftTypography color="dark" variant="body2" mt={6}>
	            		This site has been designed to help manufacturers address the two
	            		biggest needs they face today: Access to capital, and access to 
	            		programs and funding to grow a tailored, trained and skilled 
	            		workforce. To get started, just check the box below and we will 
	            		help you begin to address the issue you want to resolve today.
	          		</SoftTypography>	          	
	          	</Grid>
	        </Grid>
	        <SoftBox textAlign="center" mx="auto" mt={6}>
		        <Grid container justifyContent="center">  	
	            	<Grid item xs={12}>
			            <SoftBox display="flex" alignItems="center" justifyContent="center" mt={1} ml={1}>
			                	<Switch
			                		//disabled={workforce===true}
			                  		checked={capital===true}
			                	/>
				                <SoftTypography ml={2} variant="h4" fontWeight="bold" color="text">
				                  Access to capital
				                </SoftTypography>
			            </SoftBox>	
	            	</Grid>
	           </Grid>	
	        </SoftBox>

	        <Grid container>
	        	<Grid item xs={2}>
	        	</Grid>
	        	<Grid item xs={8}>
	        		{capital===false ||
	        			<>
			        		<SoftBox mt={3} sx={{opacity: '0.75'}}>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="acgreen">
									  			I am looking for equipment financing
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>Access Capital Technologies (ACT)</strong> can recommend the best equipment loan for your business based on a few
									    		simple questions. To get started 
									    		<Link href="/welcome" target="_blank" color="blue">
										            &nbsp;click here.
										        </Link>

									  		</SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="acgreen">
									  			I am looking for operating capital
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		- <strong>Access Capital Technologies (ACT)</strong> can match your business with lenders that provide working capital loans
									    		based on your specific needs. To get started 
												<Link href="/welcome" target="_blank" color="blue">
										            &nbsp;click here.										            
										        </Link>
										    </SoftTypography>
										</AccordionDetails>
									</Accordion>
									<Accordion>
										<AccordionSummary
									  		expandIcon={<ExpandMoreIcon />}
										>
									  		<SoftTypography variant="h5" color="acgreen">
									  			I'm interested in small manufacturing recovery grants
									  		</SoftTypography>
										</AccordionSummary>
										<AccordionDetails>
									  		<SoftTypography variant="body2" textAlign="left">
									    		The Manufacturer’s Edge Small Manufacturing Recovery Grants 
									    		program offers consultative engagement opportunities valued at upwards of $20,000 that, 
									    		thanks to a one-time state-funding opportunity, can be implemented in your organization
									    		for a fraction of the cost. To get started 
												<Link href="https://manufacturersedge.com/grants-apply/" target="_blank" color="blue">
										            &nbsp;click here.										            
										        </Link>
										    </SoftTypography>
										</AccordionDetails>
									</Accordion>
								</SoftBox>
						</>		
					}	
				</Grid>	
			</Grid>	


	        <SoftBox textAlign="center" mx="auto" mt={16}>
	        	{/*
	        	<hr/>
	        	*/}
		        <Grid container justifyContent="center">  	
	          	<Grid item xs={4}>
						</Grid>
	        	<Grid item xs={4}>
	        		{/*
	        		<Link href="https://coloradomanufacturing.org/" target="_blank">
								<SoftBox component="img" src={cama_logo} mt={3} ml={3}
								alt="CAMA Logo" width="90%" />
							</Link>
							*/}
						</Grid>
	          <Grid item xs={4}>
						</Grid>
		      </Grid>
		    </SoftBox>      	
			</SoftBox>

	      </Card>



    <SoftBox
      sx={{position: "fixed", left: "0", bottom: "0", right: "0"}}
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
      >
        Powered by 
        &copy; {new Date().getFullYear()},
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
            Access Capital Technologies
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
      <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>


    </SoftBox>

		</>
	)	
}

export default Home;