import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import axios from "axios"

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import SoftTypography from "components/SoftTypography";
import Link from "@mui/material/Link";
import typography from "assets/theme/base/typography";
import act_logo from "assets/images/act-logo.png";

import { Formik, Form } from "formik";

import Questions from "welcome/components/eligibility";
import PreqalForm from "welcome/components/form";

import { updateContact } from 'api';

function Welcome() {
  const { size } = typography;
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [programs, setPrograms] = useState([]);
  const [eligiblePrograms, setEligiblePrograms] = useState([]);
  const [eligibility, setEligibility] = useState({'data': []});
  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');
  const [forms, setForms] = useState([]);
  const [lists, setLists] = useState([]);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = React.useState({'stub': ''});  //prevent console error from formik
  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === forms.length;
  const [contact, setContact] = useState();
  //const steps = getSteps();

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const navigate = useNavigate();


useEffect(() => {
    const getPrequal = async function () {
    	try {
	      const url = process.env.REACT_APP_PUBLIC_API;
	    	const hostname = window.location.host.split('.')[0];
	    	//console.log(hostname);
	    	const tenant = await axios.get(url + "tenant/" + hostname);

        const tenantId = tenant.data.tenantId;
        
        setContact({
          'pk': tenantId,
          'curver': '0',
          'name': '',
          'order': '',
          'state': 'active'
        });

	      const results = await axios.get(url + "prequal/" + tenantId);
	      const prequal = results.data;
        //console.log('### prequal')
        //console.log(prequal)
	      
        //setPrograms(prequal.find(p => p.name === 'programs').data.filter(p => p.state==='active'));
	      //setEligiblePrograms(prequal.find(p => p.name === 'programs').data.filter(p => p.state === 'active'));
	      
        const p = prequal.filter(p => p.name === 'programs').filter(p => p.state === 'active');
        //console.log('### p')
        //console.log(p)
        let pp = [];
        if (p.length && p.length > 0) {
          p.slice().forEach(function(program) {
            Array.prototype.push.apply(pp, program.data)
          });
        }

        //console.log('### pp')
        //console.log(pp)
        setPrograms(pp);
        setEligiblePrograms(pp);


        const eligibility = prequal.find(e =>{return e.sk === 'eligibility'});
	      eligibility.data.sort((a, b) => a.sortOrder - b.sortOrder);
	      setEligibility(eligibility);

	      //header and footer
	      const header = prequal.filter(e=>{return e.sk==='pages-header'});
	      setHeader(header[0]?.data);
	      const footer = prequal.filter(e=>{return e.sk==='pages-footer'});
	      setFooter(footer[0]?.data);

        //validationLists
        const lists = prequal.filter(e=>{return e.sk==='lists'});
        if (lists[0]) {
          const listsData = lists[0].data
          listsData.forEach(list => {
            list.values = list.values.replace(/\n/g, ',').replace(',,', ',').split(',') 
          })
          setLists(listsData);
        }

	      //active forms
	      const forms = prequal.filter(e=>{return e.sk.startsWith('forms-') && e.state==='active'})

	      //set initial values for formik
	      let allData = forms.map((form) => form.data);
	      allData = [].concat(...allData);
        //console.log('### allData')
        //console.log(allData)

	      //const allFields = allData.map((field) => {return {[field.id]: ''}});
        const allFields = allData.filter(field => field.type != 'softpull' && field.type != 'bvr').map((field) => {return {[field.id]: ''}});

        //console.log('### allFields')
        //console.log(allFields)
	      setInitialValues (Object.assign({}, ...allFields));

	      //sort
	      forms.map((form) => form.data.sort((a, b) => a.order - b.order));
	      setForms(forms.sort((a, b) => a.order - b.order));

	      let steps = ['Eligibility'];
	      setSteps(steps.concat(forms.map(f=>{return f.name})));

	      setLoading(false);
	      setFirstLoad(false);  
	    }
	    catch (error) {
	    	console.log("error: " + error);
	    	setLoading(false);
	    }  
    }
    getPrequal();
  }, [])	

  function getStepContent(stepIndex, values, errors, touched, validateForm) {
    //console.log(eligibility.data.length)

    if (stepIndex===0 && eligibility.data.length>0) {
      return <Questions questions={eligibility.data} />;
    }
    else {
      //console.log(forms[stepIndex-1]);
      if (stepIndex===0) {
        handleNext();
        stepIndex=1;
      }

      return <PreqalForm 
        form={forms[stepIndex-1]} 
        values={values} 
        errors={errors} 
        touched={touched}
        validateForm={validateForm}
      />;
    }  
    

    //switch (stepIndex) {
    //  case 0:
    //    return <Questions questions={eligibility.data} />;
    //  default:
    //    //console.log(forms[stepIndex-1]);
    //    return <PreqalForm 
    //      form={forms[stepIndex-1]} 
    //      values={values} 
    //      errors={errors} 
    //      touched={touched}
    //      validateForm={validateForm}
    //    />;
    //}
  }

  const handleSubmit = async (values, actions) => {
    //console.log(values);
    if (isFirstStep) {
      validateQuestions();
    }
    else {
      const eligible = validatePrograms(values);
      //console.log('### eligible');
      //console.log(eligible);
      const updatedContact = await submitForm(values, eligible);
      //console.log('### updatedContact');
      //console.log(updatedContact);
      if (eligible?.length === 0) {
        navigate(`/noteligible`);
      }
      else {
        if (isLastStep) {
          navigate(
            `/eligible`,
            {
              state: {
                "contact": updatedContact,
                "eligiblePrograms": eligible
              }
            }
          );    
        }
        else {
          handleNext();
          actions.setTouched({});

        }
      }  
    }  
    actions.setSubmitting(false);
  };

  const submitForm = async (values, eligible ) => {
    var contactClone = structuredClone(contact);

    let eligibleList = '';
    for (const eligible of eligiblePrograms) {
      eligibleList += (eligible.code + ' ');
    }

    contactClone.data = {
      'formData': values,
      'eligiblePrograms': eligible,
      'lastUpdate': Date.now(),
      'eligibleList': eligibleList
    };
    const updatedContact = await updateContact(contactClone);
    setContact(updatedContact);
    return updatedContact;
  }

  const validateQuestions = () => {
    //console.log(eligibility.data);
    const errors = eligibility.data.filter(q=>{return (q.requires !== (q.value || false))}); 
    if (errors.length > 0) {
      navigate(`/noteligible`);
    }
    else {
      handleNext();
    }
  }

  const validatePrograms = (values) => {
    const currentForm = forms[activeStep-1];
    //console.log('### CURRENT FORM');
    //console.log(currentForm);
    let eligible = [...eligiblePrograms];
    programs.forEach(program => {
      //console.log(program);    
      currentForm.data.filter(f => f.state==='active').forEach(field => {
        const programCode = program.code;
        field.rules.map(rule => {
          let isEligible = true;
          if (rule.program===programCode) {
            //console.log('evaluate rules for field ' + field.id + ' with value ' + values[field.id])
            //console.log(rule);
            if (rule.operator==='in') {
              const list = lists.filter(list => list.id===rule.value)[0].values;
              //console.log(values[field.id] + " " + rule.operator + " " + rule.value);
              //console.log(list);
              isEligible = list.includes(values[field.id].toString());
            }
            else if (field.type==='string' || field.type==='list') {
              //console.log("'" + values[field.id] + "'" + rule.operator + "'" + rule.value + "'");
              //console.log(eval("'" + values[field.id] + "'" + rule.operator + "'" + rule.value + "'"));
              isEligible = eval("'" + values[field.id] + "'" + rule.operator + "'" + rule.value + "'");
            }
            else if (field.type==='number' || field.type==='bool') {
              if (field.type==='number' && (!values[field.id]))
                values[field.id]=0;
              if (field.type==='bool' && (!values[field.id]))
                values[field.id]=false;
              console.log(values[field.id] + rule.operator + rule.value);
              console.log(eval(values[field.id] + rule.operator + rule.value));
              isEligible = eval(values[field.id]+ rule.operator + rule.value);                
            } 

            //console.log(program.code + (isEligible ? ' is ' : ' is not ') + 'eligible')
            if (!isEligible) {
              //console.log('### REMOVE ' + programCode);
              var foundIndex = eligible.findIndex(e => e.code === program.code);
              if (foundIndex > -1) {
                //console.log(foundIndex);
                eligible.splice(foundIndex, 1);
              }
              //console.log(eligible);
              setEligiblePrograms(eligible);
            }
          }
        })
      })
    })
    return eligible;  
  }

  const validateForms = values => {
    const errors = {};
    
    if (activeStep > 0) {
      const currentForm = forms[activeStep-1]; 

      currentForm.data.forEach(field => {
        //console.log(field);
        //console.log(values[field.id]);
        if (!values[field.id] && field.required && field.state === 'active') {
          if (field.type==='number') {
            errors[field.id] = 'Number Required';
          }
          else {
            errors[field.id] = 'Required';
          }
        }
      })
    }
    return errors;
  };

	return (

    <>


    <SoftBox
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
      sx={{position: "fixed", top: "0px", zIndex: "999"}}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
        px={1.5}
      >
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
        <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>
    </SoftBox>

    <Card>

      {loading &&
        <>
        <Grid container>    
          <Grid item xs={4}>
          {/**
            <SoftBox component="img" src={act_logo} mt={6} ml={16}>
            </SoftBox>
          **/}
          </Grid>
        </Grid>    
        <SoftBox>
          <SoftTypography mt={8} variant="h3" textAlign="center">
            <CircularProgress color="dark"/>
          </SoftTypography>
        </SoftBox>
        </>  
      }   

      {!firstLoad &&
	      <SoftBox pt={2} pb={8}>

      {!loading &&
        <SoftBox sx={{ height: '2px;' }}>   
        </SoftBox>  
      }



	        <Grid container justifyContent="center">
	          <Grid item xs={12} lg={10}>
	            <SoftBox mt={4} mb={1}>
	              <div dangerouslySetInnerHTML={{ __html: header }} />
	            </SoftBox>
							<Stepper activeStep={activeStep} alternativeLabel>
	              {steps.map((label, index) => (
	                <Step key={index}>
	                  <StepLabel>{label}</StepLabel>
	                </Step>
	              ))}
	            </Stepper>          
	            <Formik
	              enableReinitialize
	              initialValues={initialValues}
	              validate={validateForms}
	              onSubmit={handleSubmit}              
	            >
	              {({ values, errors, touched, isSubmitting, validateForm }) => (
	                <Form id='prequal'>
	                  <Card>
	                    <SoftBox p={2}>
	                        {getStepContent(activeStep, values, errors, touched, validateForm)}
	                        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
	                          {activeStep === 0 ? (
	                            <SoftBox />
	                          ) : (
	                            <SoftButton variant="gradient" color="light" onClick={handleBack}>
	                              back
	                            </SoftButton>
	                          )}
                            {!isSubmitting &&
  	                          <SoftButton
  	                            disabled={isSubmitting}
  	                            variant="gradient"
  	                            color="dark"
  	                            type="submit"
  	                            //onClick={!isLastStep ? handleNext : undefined}
  	                          >
  	                            {isLastStep ? "submit" : "next"}
  	                          </SoftButton>
                            }  
                            {isSubmitting &&
                              <CircularProgress color="dark"/>
                            }  
	                      </SoftBox>
	                    </SoftBox>
	                  </Card>
	                </Form>
	              )}
	            </Formik>
	            <SoftBox mt={12} mb={1}>
	            	<hr />
	              <div dangerouslySetInnerHTML={{ __html: footer }} />
	            </SoftBox>
	          </Grid>
	        </Grid>
	      </SoftBox>
	    }
    </Card>

    <SoftBox
      sx={{position: "fixed", left: "0", bottom: "0", right: "0"}}
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
      >
        Powered by 
        &copy; {new Date().getFullYear()},
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
            Access Capital Technologies
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
      <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>


    </SoftBox>
    </>
            		
	);
}

export default Welcome;